import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import image1 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/long-live-the-queen_glass_hair_found-objects_freshwater-pearls.jpg'
import image2 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/unnecessary-belongings_ceramic_silver_found-object_metal-thread.jpg'
import image3 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/the-angel-guest.jpg'
import image4 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/angel-guest-with-flower.jpg'
import image5 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/medium-garden_ceramic_repurposed-fur_found-object_freshwater-pearls.jpg'
import image6 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/small-garden_ceramic_repurposed-fur_found-objects_freshwater-pearls.jpg'
import image7 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/no-tiny-garden-is-insignificant_ceramic_repurposed-fur_semi-precious-stones.jpg'
import image8 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-in-a-circle_ceramic-installation.jpg'
import image9 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/sprout-off-the-wall_ceramic-mixed-media-3ft-approx.jpg'
import image10 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/winter-love_ceramic-sculpture-12hx14d-each-approx.jpg'
import image11 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/discarded-&-served_ceramic-sculpture-&-mixed-media_4x15x10in.jpg'
import image12 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/environmental-disturbance_ceramic-glaze-mixed-media-12dimx4h-in.jpg'
import image13 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-2_ceramic-mixed-media-7x4in.jpg'
import image14 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-4_ceramic-mixed-media-private-collection.jpg'
import image15 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-5_ceramic-private-collection.jpg'
import image16 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-6_ceramic-mixed-media-private-collection.jpg'
import image17 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled-7_ceramic-mixed-media-private-colection.jpg'
import image18 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/tangled_3-pc-installation.jpg'
import image19 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/ceramic-flower_3hx10lin.jpg'
import image20 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/ceramic-oval-vase-with-flower_5hx8lx4din.jpg'
import image21 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/ceramic-vases-installation-at-tempeton-general-boston.jpg'
import image22 from '../../../assets/images/albums/ceramics-and-mixed-media/recent-works/hanging-garden_ceramic-mixed-media-installation-at-templeton-general-boston.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: image1,
    width: 686,
    height: 960,
    caption: 'Long Live the Queen - glass - hair - silver - cut glass gems - found objects - wood - 10.5"H x 7"W x 7"D'
  },
  {
    src: image2,
    width: 960,
    height: 788,
    caption: 'Unnecessary Belongings - ceramic - silver - found object - 2"H x 26"L x 5.5"D'
  },
  {
    src: image3,
    width: 960,
    height: 804,
    caption: 'The Angel Guest - ceramic - glaze - repurposed fur - freshwater pearls - 10.5" H x 12" W x 8" D'
  },
  {
    src: image4,
    width: 960,
    height: 796,
    caption: 'The Angel Guest with Flower - ceramic - glaze - repurposed fur - freshwater pearl and vintage metal flower - 8" H x 7" H x 4" D'
  },
  {
    src: image5,
    width: 720,
    height: 960,
    caption: 'Medium Garden -  ceramic - repurpose fur - found object - freshwater pearls - 4.5"H x 4.75"D'
  },
  {
    src: image6,
    width: 703,
    height: 960,
    caption: 'Small Garden - ceramic - repurpose fur - found object - freshwater pearls - 4"H x 3"D'
  },
  {
    src: image7,
    width: 720,
    height: 960,
    caption: 'No Tiny Garden is Insignificant - ceramic - repurpose fur - found object - freshwater pearls - 4.2"5H x 2.5"D'
  },
  {
    src: image8,
    width: 960,
    height: 540,
    caption: 'Tangled in a Circle - ceramic installation - 13"x4" approx.'
  },
  {
    src: image9,
    width: 960,
    height: 540,
    caption: 'Sprout Off the Wall - mixed media - 3\' approx.'
  },
  {
    src: image10,
    width: 960,
    height: 640,
    caption: 'Winter Love - ceramic sculpture dyptich - 12"x14"'
  },
  {
    src: image11,
    width: 960,
    height: 540,
    caption: 'Discarded and Served - ceramic sculpture and mixed media - 4"x15"x10"'
  },
  {
    src: image12,
    width: 960,
    height: 720,
    caption: 'Environmental Disturbance - ceramic sculpture & mixed media - 12"x4"'
  },
  {
    src: image13,
    width: 769,
    height: 960,
    caption: 'Tangled #2 - ceramic & mixed media - 7"x4" - Private Collection'
  },
  {
    src: image14,
    width: 720,
    height: 960,
    caption: 'Tangled #4 - ceramic & mixed media - Private collection'
  },
  {
    src: image15,
    width: 960,
    height: 760,
    caption: 'Tangled #5 - ceramic & mixed media - Private collection'
  },
  {
    src: image16,
    width: 960,
    height: 720,
    caption: 'Tangled #6 - ceramic & mixed media - Private collection'
  },
  {
    src: image17,
    width: 960,
    height: 720,
    caption: 'Tangled #7 - ceramic & mixed media - Private collection'
  },
  {
    src: image18,
    width: 960,
    height: 800,
    caption: 'Tangled - 3-piece wall installation'
  },
  {
    src: image19,
    width: 960,
    height: 720,
    caption: 'Ceramic Flower - ceramic sculpture - 3"Hx10"L'
  },
  {
    src: image20,
    width: 960,
    height: 720,
    caption: 'Ceramic Oval Vase with Flower - 5"x8"x4"'
  },
  {
    src: image21,
    width: 960,
    height: 540,
    caption: 'Ceramic Vases - installation at Templeton General, Boston - Private collection'
  },
  {
    src: image22,
    width: 540,
    height: 960,
    caption: 'Hanging Garden - ceramic mixed media instalation at Templeton General, Boston - Private Collection'
  }
]
